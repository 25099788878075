// PAGE SINGLE SIT
function callbackMapSingleSIT(macarte) {
    macarte.zoomControl.setPosition('topright');
    macarte.setZoom(14);
    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}


// PAGE SINGLE SIT
function callbackMapOverlayStation(macarte) {
    macarte.zoomControl.remove();
    macarte.setZoom(14);

    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}


/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {

    if (overlayId === 'overlay-station') {
        setTimeout(function () {
            const myMap = document.querySelector('.js-overlay-map-station');

            if (myMap && !myMap.classList.contains('leaflet-container')) {
                th_maps.initSingleMap(myMap);
            }
        }, 850);
    }
});