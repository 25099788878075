class ThAccordion {
    constructor(rootNode) {
        this.rootEl = rootNode;     // .js-accordion
        this.btnTrigger = this.rootEl.querySelector('.js-accordion-trigger');

        if (this.btnTrigger) {
            const controlsId = this.btnTrigger.getAttribute('aria-controls');
            this.panel = document.getElementById(controlsId);
            this.init();
        }
    }

    init() {
        this.isOpen = this.btnTrigger.getAttribute('aria-expanded') === 'true';
        this.btnTrigger.addEventListener('click', this.onButtonClick.bind(this));
    }

    onButtonClick(e) {
        (this.isOpen) ? this.close() : this.open();
    }

    open() {
        // Envoie un event récupéré par la classe ThAccordionGroup pour refermer les autres accordéons
        this.rootEl.dispatchEvent(new CustomEvent("onAccordionOpen", {
            detail: { accordionInstance: this }
        }));

        this.isOpen = true;
        this.btnTrigger.setAttribute('aria-expanded', true);
        this.panel.removeAttribute('aria-hidden');
        this.panel.removeAttribute('inert');
        this.rootEl.classList.add('is-open');
    }

    close() {
        this.isOpen = false;
        this.btnTrigger.setAttribute('aria-expanded', false);
        this.panel.setAttribute('aria-hidden', 'true');
        this.panel.setAttribute('inert', '');
        this.rootEl.classList.remove('is-open');
    }
}