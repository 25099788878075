const th_overlay = new ThOverlay();


// Scroll smooth jusqu'à une ancre
document.querySelectorAll('.js-scroll-to-target').forEach(el => {
    el.addEventListener('click', (e) => {
        e.preventDefault();

        // Ferme au préalable un overlay si ouvert
        if (th_overlay.currentOverlayId) {
            th_overlay.close(th_overlay.currentOverlayId);
        }


        const id = el.getAttribute('href');
        const target = document.querySelector(id);

        if (target) {
            // Enregistre la position courante du scroll avant le focus pour empêcher le défilement sur la section de sacader
            const currentScollX = window.scrollX;
            const currentScollY = window.scrollY;
            target.focus();
            window.scrollTo(currentScollX, currentScollY);      // repart de la position d'origine du scroll au moment du click

            let scrollOffset =  parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));
            if (!scrollOffset) scrollOffset = 80;
            scrollOffset += 20;
            scrollToTarget(target, -scrollOffset);
        }
    });
});
