// Modèle Disclosure pour afficher / masquer un contenu
class DisclosureWidget {
    constructor(buttonNode) {
        this.btnTrigger = buttonNode;       // .js-toggle-disclosure
        this.panel = null;

        const controlsId = this.btnTrigger.getAttribute('aria-controls');
        this.panel = document.getElementById(controlsId);
        this.pressEscEventListener = e => this.detectEscapeKey(e);

        if(this.panel) this.init();
    }

    init() {
        if (this.btnTrigger.getAttribute('aria-expanded')) this.hideContent();
        this.btnTrigger.addEventListener('click', this.toggleExpand.bind(this));
    }

    showContent() {
        this.btnTrigger.setAttribute('aria-expanded', 'true');
        this.panel.classList.remove('content-hidden');

        document.addEventListener('keydown', this.pressEscEventListener);
    }

    hideContent() {
        this.btnTrigger.setAttribute('aria-expanded', 'false');
        this.panel.classList.add('content-hidden');

        document.removeEventListener('keydown', this.pressEscEventListener);
    }

    toggleExpand() {
        if (this.btnTrigger.getAttribute('aria-expanded') === 'true') {
            this.hideContent();
        } else {
            this.showContent();
        }
    }

    // Close if ESC key pressed
    detectEscapeKey(e) {
        if (e.key === "Escape") {
            this.hideContent();
        }
    }
}