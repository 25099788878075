// Scripts pour ajouter ou supprimer la classe "no-scroll"
function addNoScroll() {
    if (!document.body.classList.contains("no-scroll")) {
        document.body.classList.add("no-scroll")
    }
}

function removeNoScroll() {
    if (document.body.classList.contains("no-scroll")) {
        document.body.classList.remove("no-scroll");
    }
}

th_overlay.addCallbackOpen(addNoScroll);
th_overlay.addCallbackClose(removeNoScroll);


// Active / désactive les boutons de la barre sticky sur les fiches SIT
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId === 'overlay-station' ||
        overlayId === 'overlay-weather' ||
        overlayId === 'overlay-weather-snow' ||
        overlayId === 'overlay-access') {
        const btn = document.querySelector(`.btn-quick-access-sit[data-overlay-open="${overlayId}"]`);
        if (btn) btn.classList.add('is-active');
    }
});


th_overlay.addCallbackClose(function (overlayId) {
    if (overlayId === 'overlay-station' ||
        overlayId === 'overlay-weather' ||
        overlayId === 'overlay-weather-snow' ||
        overlayId === 'overlay-access') {
        const btn = document.querySelector(`.btn-quick-access-sit[data-overlay-open="${overlayId}"]`);
        if (btn) btn.classList.remove('is-active');
    }
});
