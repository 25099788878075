(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-social');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            mediaQuery: 'min',
            arrows: false,
            breakpoints: {
                1240: {
                    destroy: true,
                },
                600: {
                    arrows: true,
                },
            }
        }).mount();
    });
})();


